import { showMessage } from 'app/store/fuse/messageSlice';

export const showError = (dispatch, message) =>
	dispatch(
		showMessage({
			message,
			variant: 'error'
		})
	);

export const showResult = (dispatch, response, displayOk:boolean = true, defaultMessage:string = 'Action réalisée avec success') => {
	if (response.data.status === false) {
		dispatch(
			showMessage({
				message: response.data.error,
				autoHideDuration: 6000,
				variant: 'error'
			})
		);
	} else if (displayOk && response.status !== 200) {
		dispatch(
			showMessage({
				message: response.data.message ?? defaultMessage,
				autoHideDuration: 3000,
				variant: 'success'
			})
		);
	}
	return response.data;
};
