import AccountingAppConfig from './accounting/AccountingAppConfig';
import DashboardAppConfig from './dashboards/DashboardAppConfig';
import FileManagerAppConfig from './file-manager/FileManagerAppConfig';
import MailAppConfig from './mail/MailAppConfig';
import OrdersAppConfig from './orders/OrdersAppConfig';
import SecretariatAppConfig from './secretariat/SecretariatAppConfig';

const AppsConfigs = [
	AccountingAppConfig,
	DashboardAppConfig,
	FileManagerAppConfig,
	MailAppConfig,
	OrdersAppConfig,
	SecretariatAppConfig
];

export default AppsConfigs;
