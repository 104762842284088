import _ from 'lodash';
import { OrderDetailType, OrderType, StatusType } from 'models/Orders';
import { PaymentDetailType, PaymentType } from 'models/Payments';
import { FamilyMemberType, MemberType } from 'models/Member';
import { ProductType } from 'models/Products';
import { UserType } from 'models/Users';
import { calculateAge, dateTimeHTML, formatDateRange } from './date';
import { sortByName } from './member';
import { randNumber } from './record';

export const addStatus = (statuses: StatusType[], value: number) => {
  if (statuses.find((item) => item.value === value) !== undefined) return statuses;

  return [...statuses, { value, date: dateTimeHTML() }];
};

export const getStatusDate = (statuses: StatusType[], value: number) => {
  const status = statuses?.find((item) => item.value === value);
  return status ? status.date : undefined;
};

export const receivedPayments = (payment: PaymentType) =>
  ['new', 'delivered', 'delay', 'succeeded'].includes(payment.status);

export const sumPayments = (payments: PaymentType[]) =>
  payments
    .filter(receivedPayments)
    .reduce((result, payment) => result + Number(payment.amount), 0) ?? 0;

export const sumReceivedPayments = (payments: PaymentType[]) =>
  payments
    .filter(receivedPayments)
    .reduce((result, payment) => result + Number(payment.received_amount), 0) ?? 0;

export const filterByDetailStatus = (detail: OrderDetailType, status: string) =>
  status ? status === detail.status : !['cancel', 'waiting'].includes(detail.status);

export const mapParticipantByVariant = (variantsParticipants: any[], status: string) => {
  return variantsParticipants
    .reduce((acc, curr, index) => {
      curr.order_details
        ?.filter((detail) => filterByDetailStatus(detail, status))
        .forEach((detail) => {
          const participantId = acc.findIndex(
            (item) => item.participant_id === detail.participant_id,
          );
          if (participantId === -1) {
            const variants = [false, false, false];
            variants[index] = detail.quantity;
            acc.push({ ...detail, variants });
          } else {
            acc[participantId].variants[index] += detail.quantity;
          }
        });
      return acc;
    }, [])
    .sort((a: FamilyMemberType, b: FamilyMemberType) => sortByName(a, b));
};

const getProductParticipants = (order: OrderType, member: MemberType, product: ProductType) => {
  return member.family
    .filter((item) => item.type === 'child' && !!item.birthdate)
    .map((item) => {
      const ageWithProduct = calculateAge(item.birthdate, product.date_from);
      return {
        id: item.id,
        name: `${item.firstname} ${item.lastname} (${ageWithProduct} ans)`,
        age: ageWithProduct,
        variants:
          (product.variants &&
            product.variants
              .filter((variant) => variant.type !== 'member')
              .sort((a, b) => (a.date_from > b.date_from ? 1 : -1))
              .map((variant) => {
                const ageWithVariant = calculateAge(item.birthdate, variant.date_from);
                const row = order.details?.find(
                  (detail) => detail.participant_id === item.id && detail.variant_id === variant.id,
                );
                const isValid =
                  ageWithVariant >= variant.age_min && ageWithVariant <= variant.age_max;
                const orderDetail = {
                  id: row?.id,
                  variant_id: variant.id,
                  name: variant.name,
                  label: `${variant.name} ${formatDateRange(variant.date_from, variant.date_to)}`,
                  valid: isValid,
                  quantity: row?.quantity ?? 0,
                  price: variant.price,
                  maxQuantity: variant.quantity,
                  orderCount: variant.order_count,
                  orderWaiting: variant.order_waiting,
                  comment: '',
                };
                return { ...orderDetail, status: newStatus(orderDetail) };
              })) ||
          [],
      };
    })
    .sort((a, b) => (a.age > b.age ? 1 : -1));
};

export const buildOrder = (member: MemberType, products: ProductType[], order: OrderType) => {
  if (!member || !products || products.length === 0) return [];

  const stayProducts = products
    .filter((product) => ['stay', 'option'].includes(product.category))
    .map((product) => {
      const participants = getProductParticipants(order, member, product);
      const valid = participants.reduce(
        (participantResult, participant) =>
          participantResult ||
          participant.variants.reduce(
            (variantResult, variant) => variantResult || variant.valid,
            false,
          ),
        false,
      );
      return {
        id: product.id,
        name: product.name,
        comment: product.comment,
        mandatory: product.mandatory,
        category: product.category,
        participants,
        valid,
        age_min: product.variants.reduce(
          (result, item) => (result > item.age_min ? item.age_min : result),
          99,
        ),
        quantity: product.quantity,
        date_from: product.date_from,
        date_to: product.date_to,
        date_max: product.date_max,
        date_open: product.date_open,
        pre_order: product.pre_order,
      };
    })
    .sort((a, b) => (`${a.date_from}.${a.age_min}` > `${b.date_from}.${b.age_min}` ? 1 : -1));

  const contributionProducts = products
    .filter((product) => product.category === 'contribution')
    .map((product) => ({
      id: product.id,
      name: product.name,
      comment: product.comment,
      mandatory: product.mandatory,
      category: product.category,
      participants: [
        {
          id: member.id,
          name: `${member.firstname} ${member.lastname}`,
          display: false,
          variants:
            product.variants
              ?.filter((variant) => variant.type === 'member')
              .sort((a, b) => (a.age_min > b.age_min ? 1 : -1))
              .map((variant) => {
                const value = member.info[product.category];
                const isInfoSet = value
                  ? variant.date_from <= value && value <= variant.date_to
                  : false;
                const row = order.details?.find(
                  (detail) =>
                    detail.participant_id === member.id && detail.variant_id === variant.id,
                );
                let quantity = 0;
                if (row) {
                  quantity = row.quantity;
                } else if (isInfoSet) {
                  quantity = 0;
                } else if (product.mandatory) {
                  quantity = 1;
                }

                return {
                  id: row ? row.id : undefined,
                  variant_id: variant.id,
                  name: variant.name,
                  label: `${product.name} - ${variant.name}`,
                  valid: product.active,
                  quantity,
                  price: variant.price,
                  disabled: product.mandatory,
                  comment: isInfoSet ? `déjà réglée le ${value}` : '',
                };
              }) || [],
        },
      ],
      valid: true,
      quantity: product.quantity,
      date_max: product.date_max,
      date_from: product.date_from,
      date_to: product.date_to,
      date_open: product.date_open,
      pre_order: product.pre_order,
    }));

  const goodProducts = products
    .filter((product) => ['activity', 'good'].includes(product.category))
    .map((product) => ({
      id: product.id,
      name: product.name,
      comment: product.comment,
      mandatory: product.mandatory,
      category: product.category,
      participants: [
        {
          id: member.id,
          name: `${member.firstname} ${member.lastname}`,
          display: false,
          variants:
            (product.variants &&
              product.variants
                .filter((variant) => variant.type === 'member')
                .sort((a, b) => (a.date_from > b.date_from ? 1 : -1))
                .map((variant) => {
                  const value = member.info[product.category];
                  const isInfoSet = value
                    ? variant.date_from <= value && value <= variant.date_to
                    : false;
                  const row = order.details.find(
                    (detail) =>
                      detail.participant_id === member.id && detail.variant_id === variant.id,
                  );
                  let quantity = 0;
                  if (row) {
                    quantity = row.quantity;
                  } else if (!isInfoSet) {
                    quantity = product.mandatory ? 1 : 0;
                  }

                  return {
                    id: row ? row.id : undefined,
                    variant_id: variant.id,
                    name: variant.name,
                    label: `${product.name} - ${variant.name}`,
                    valid: product.active,
                    quantity,
                    price: variant.price,
                    disabled: product.mandatory,
                    comment: '',
                  };
                })) ||
            [],
        },
      ],
      valid: true,
      quantity: product.quantity,
      date_from: product.date_from,
      date_to: product.date_to,
      date_max: product.date_max,
      date_open: product.date_open,
      pre_order: product.pre_order,
    }));

  return [...goodProducts, ...stayProducts, ...contributionProducts];
};

export const paymentsByProduct = (payments: PaymentType[]) => {
  if (!payments) return {};

  return payments.filter(receivedPayments).reduce((acc, payment) => {
    payment.details.forEach((detail) => {
      if (!acc[detail.product_id]) {
        acc[detail.product_id] = detail.amount;
      } else {
        acc[detail.product_id] += detail.amount;
      }
    });
    return acc;
  }, {});
};

export const orderedByProduct = (order: OrderType, variants: any[]) => {
  if (!order.details) return {};

  const payments = paymentsByProduct(order.payments);
  return order.details.reduce((acc, detail) => {
    const variant = variants.find((item) => item.variant_id === detail.variant_id);
    if (!variant) return {};

    const total = Number(detail.total);
    return {
      ...acc,
      [variant.product_id]: {
        name: variant.product_name,
        category: variant.category,
        amount: !acc[variant.product_id] ? total : Number(acc[variant.product_id].amount) + total,
        paid: payments[variant.product_id] || 0,
        count: !acc[variant.product_id] ? 1 : Number(acc[variant.product_id].count) + 1,
      },
    };
  }, {});
};

export const getContributionInOrder = (ordered: any, value: any): PaymentDetailType => {
  const productId = _.findKey(ordered, (item) => item.category === 'contribution');
  if (!productId) return undefined;

  const amount = Math.min(value, ordered[productId].amount - ordered[productId].paid);
  return {
    product_id: productId,
    amount,
    currency: 'EUR',
  };
};

export const orderByProducts = (order: any) => {
  const products = order.details
    .reduce((results, current) => {
      if (current.variant.product.category === 'contribution') {
        return results;
      }
      const currentParticipant = {
        id: current.participant_id,
        name: current.name,
        attachments: current.attachments,
        status: current.status,
        ranking: current.ranking,
        variants: [
          {
            id: current.variant_id,
            detailId: current.id,
            name: current.variant.name,
            status: current.status,
          },
        ],
      };

      const iProduct = results.findIndex((result) => result.id === current.variant.product_id);
      if (iProduct === -1) {
        return [
          ...results,
          {
            ...current.variant.product,
            id: current.variant.product_id,
            participants: [currentParticipant],
          },
        ];
      }
      const iParticipant = results[iProduct].participants.findIndex(
        (participant) => participant.id === current.participant_id,
      );
      if (iParticipant === -1) {
        results[iProduct].participants = [...results[iProduct].participants, currentParticipant];
      } else {
        results[iProduct].participants[iParticipant].variants = [
          ...results[iProduct].participants[iParticipant].variants,
          currentParticipant.variants[0],
        ].sort((a, b) => (a.name < b.name ? -1 : 1));
        results[iProduct].participants[iParticipant].attachments = [
          ...results[iProduct].participants[iParticipant].attachments,
          ...current.attachments,
        ];
      }
      return results;
    }, [])
    .sort((a, b) => (a.date_from < b.date_from ? -1 : 1));

  return {
    ...order,
    closed: order.current_status >= 90,
    products,
  };
};

export const isFullPaidOrder = (order: OrderType) => parseInt(order.balance, 10) <= 0;
export const isPaidOrderDeposit = (order: OrderType) => parseInt(order.paid, 10) !== 0;

export const blankOrder = (user: UserType) => ({
  user_id: user.id,
  member_id: user.id,
  member: undefined,
  reference: randNumber(6),
  subtotal: 0,
  discount: 0,
  total: 0,
  status: [{ value: 0, date: dateTimeHTML() }],
  details: [],
});

export const newStatus = (variant: any, preOrder?: boolean) => {
  if (variant.quantity === 0) {
    return undefined;
  }
  if (preOrder) {
    return 'pre-order';
  }
  if (0.9 * variant.maxQuantity <= variant.orderCount) {
    return 'waiting';
  }
  return 'new-order';
};
