import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { showResult, showError } from 'helpers/actions';
import { apiUrl, buildRequest, cleanRecord } from 'helpers/record';
import lexicon from 'constant/lexicon';

const urlMember = apiUrl('members');
const urlFamily = apiUrl('family');
const urlMemberMail = apiUrl('log_mail');

export const getMember = createAsyncThunk('secretariatApp/member/getMember', async (id, { dispatch }) => {
	const data = await axios
		.get(`${urlMember}/${id}`)
		.then(response => showResult(dispatch, response, false))
		.catch(error => showError(dispatch, error));
	return data;
});

export const getMemberFamily = createAsyncThunk('secretariatApp/member/getMemberFamily', async (id, { dispatch }) => {
	const data = await axios
		.get(`${urlFamily}/${id}`)
		.then(response => showResult(dispatch, response, false))
		.catch(error => showError(dispatch, error));
	return data;
});

export const getMemberMails = createAsyncThunk('secretariatApp/member/getMemberMails', async (query, { dispatch }) => {
	const request = buildRequest(query);
	const data = await axios
		.get(`${urlMemberMail}${request}`)
		.then(response => showResult(dispatch, response, false))
		.catch(error => showError(dispatch, error));
	return data;
});

export const addMember = createAsyncThunk('secretariatApp/member/addMember', async (member, { dispatch }) => {
	const data = await axios
		.post(urlMember, cleanRecord(member))
		.then(response => showResult(dispatch, response, true, `Nouveau ${lexicon('member')} créé`))
		.catch(error => showError(dispatch, error));
	return data;
});

export const updateMember = createAsyncThunk('secretariatApp/member/updateMember', (member, { dispatch }) => {
	const data = axios
		.put(`${urlMember}/${member.id}`, cleanRecord(member))
		.then(response => showResult(dispatch, response, true, `Fiche ${lexicon('member')} enregistrée`))
		.catch(error => showError(dispatch, error));
	return data;
});

export const saveMemberFamily = createAsyncThunk('secretariatApp/member/saveMemberFamily', (family, { dispatch }) => {
	const record = cleanRecord(family);
	const result = record.id ? axios.put(`${urlFamily}/${record.id}`, record) : axios.post(`${urlFamily}`, record);
	const data = result
		.then(response => showResult(dispatch, response, true, `Fiche ${lexicon('member')} enregistrée`))
		.catch(error => showError(dispatch, error));
	return data;
});

export const removeMemberFamily = createAsyncThunk(
	'secretariatApp/member/removeMemberFamily',
	async ({ id }, { dispatch }) => {
		const response = await axios
			.delete(`${urlFamily}/${id}`)
			.then(() => id)
			.catch(error => showError(dispatch, error));
		const data = await response.data;
		return data;
	}
);

const initialState = {
	entity: {
		firstname: '',
		lastname: '',
		address: '',
		postal_code: '',
		city: '',
		country: '',
		phone: '',
		mobile: '',
		email_perso: '',
		email_prof: '',
		emailing: 1,
		comment: '',
		family: [],
		info: {},
		active: 1
	},
	mails: {
		total_count: 0,
		results: []
	},
	memberFamilyDialog: {
		type: 'new',
		props: {
			open: false
		},
		data: null
	}
};

const memberSlice = createSlice({
	name: 'secretariatApp/member',
	initialState,
	reducers: {
		resetMember: () => initialState,
		newMember: {
			reducer: (state, action) => {
				state.entity = action.payload.entity;
			},
			prepare: member => ({
				payload: {
					...initialState,
					entity: { ...initialState.entity, ...member }
				}
			})
		},
		openNewMemberFamilyDialog: (state, action) => {
			state.memberFamilyDialog = {
				type: 'new',
				props: { open: true },
				data: null
			};
		},
		closeNewMemberFamilyDialog: (state, action) => {
			state.memberFamilyDialog = {
				type: 'new',
				props: { open: false },
				data: null
			};
		},
		openEditMemberFamilyDialog: (state, action) => {
			state.memberFamilyDialog = {
				type: 'edit',
				props: { open: true },
				data: action.payload
			};
		},
		closeEditMemberFamilyDialog: (state, action) => {
			state.memberFamilyDialog = {
				type: 'edit',
				props: { open: false },
				data: null
			};
		}
	},
	extraReducers: {
		[getMember.fulfilled]: (state, action) => ({ ...state, entity: action.payload }),
		[getMemberFamily.fulfilled]: (state, action) => ({
			...state,
			entity: { ...state.entity, family: action.payload }
		}),
		[getMemberMails.fulfilled]: (state, action) => ({ ...state, mails: action.payload }),
		[addMember.fulfilled]: (state, action) => ({ ...state, entity: action.payload }),
		[updateMember.fulfilled]: (state, action) => ({ ...state, entity: action.payload }),
		[saveMemberFamily.fulfilled]: (state, action) => {
			const familyMember = action.payload;
			const family = state.entity.family.slice();
			const idx = family.findIndex(row => row.id === familyMember.id);
			if (idx === -1) {
				family.push(familyMember);
			} else {
				family[idx] = familyMember;
			}
			return {
				...state,
				entity: {
					...state.entity,
					family
				}
			};
		},
		[removeMemberFamily.fulfilled]: (state, action) => ({
			...state,
			entity: {
				...state.entity,
				family: state.entity.family.filter(row => row.id !== action.payload)
			}
		})
	}
});

export const {
	closeEditMemberFamilyDialog,
	closeNewMemberFamilyDialog,
	newMember,
	openEditMemberFamilyDialog,
	openNewMemberFamilyDialog,
	resetMember
} = memberSlice.actions;

export default memberSlice.reducer;
