import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';

const AccountingAppConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.staff,
	routes: [
		{
			path: '/apps/accounting/payments/delivery',
			component: lazy(() => import('./payments/PaymentsDelivery'))
		},
		{
			path: '/apps/accounting/payments/:id/:paymentHandle?',
			component: lazy(() => import('./payment/Payment'))
		},
		{
			path: '/apps/accounting/payments',
			component: lazy(() => import('./payments/Payments'))
		},
		{
			path: '/apps/accounting',
			component: () => <Redirect to="/apps/payments/Payments" />
		}
	]
};

export default AccountingAppConfig;
