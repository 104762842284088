export const newAttachment = (
	category: string,
	id: string,
	formData: FormData,
	memberId: string
) => {
	const file = formData.get('filename');
	const type = typeof file === 'string' ? (/\.([0-9a-z]+$)/i).exec(file)[1] : file.type;
	// const type = typeof file === 'string' ? file.match(/\.([0-9a-z]+$)/i)[1] : file.type;
	return {
		category,
		parent_id: id,
		file,
		type,
		status: 'new',
		id: memberId,
	};
};
