import i18next from 'i18next';
import React from 'react';
import { Redirect } from 'react-router-dom';
import en from './i18n/en';
import fr from './i18n/fr';

i18next.addResourceBundle('en', 'mailApp', en);
i18next.addResourceBundle('fr', 'mailApp', fr);

const MailAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: [
				'/apps/mail/label/:labelHandle/:mailId?',
				'/apps/mail/filter/:filterHandle/:mailId?',
				'/apps/mail/:folderHandle/:mailId?'
			],
			component: React.lazy(() => import('./MailApp'))
		},
		{
			path: '/apps/mail',
			component: () => <Redirect to="/apps/mail/INBOX" />
		}
	]
};

export default MailAppConfig;
