import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';

const FileManagerAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.staff,
	routes: [
		{
			path: '/apps/file-manager',
			component: lazy(() => import('./FileManagerApp'))
		}
	]
};

export default FileManagerAppConfig;
