import { Roles } from 'constant/user';

/**
 * Authorization Roless
 */
const authRoles = {
  admin: [Roles.ADMIN],
  staff: [Roles.ADMIN, Roles.STAFF],
  user: [Roles.ADMIN, Roles.STAFF, Roles.USER],
  onlyGuest: [],
};

export default authRoles;
