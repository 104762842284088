import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';

const SecretariatAppConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.staff,
	routes: [
		{
			path: '/apps/secretariat/members/team',
			component: lazy(() => import('./members/Members'))
		},
		{
			path: '/apps/secretariat/members/office',
			component: lazy(() => import('./members/Members'))
		},
		{
			path: '/apps/secretariat/members/:id/:memberHandle?',
			component: lazy(() => import('./member/Member'))
		},
		{
			path: '/apps/secretariat/members',
			component: lazy(() => import('./members/Members'))
		},
		{
			path: '/apps/secretariat/parameters',
			component: lazy(() => import('./parameters/Parameters'))
		},
		{
			path: '/apps/secretariat/products/:id',
			component: lazy(() => import('./product/Product'))
		},
		{
			path: '/apps/secretariat/products',
			component: lazy(() => import('./products/Products'))
		},
		{
			path: '/apps/secretariat/print',
			component: lazy(() => import('./print/Print'))
		},
		{
			path: '/apps/secretariat',
			component: () => <Redirect to="/apps/secretariat/members" />
		}
	]
};

export default SecretariatAppConfig;
