import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';

const OrdersAppConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.staff,
	routes: [
		{
			path: '/apps/orders/orderWizard/:id',
			auth: authRoles.admin,
			component: lazy(() => import('./orderWizard/OrderWizard'))
		},
		{
			path: '/apps/orders/orderWizard',
			auth: authRoles.admin,
			component: lazy(() => import('./orderWizard/OrderWizard'))
		},
		{
			path: '/apps/orders/variants/:id',
			component: lazy(() => import('./variant/Variant'))
		},
		{
			path: '/apps/orders/variantids=:id',
			component: lazy(() => import('./variant/Variants'))
		},
		{
			path: '/apps/orders/variants',
			component: lazy(() => import('./variants/Variants'))
		},
		{
			path: '/apps/orders/orders/:id',
			auth: authRoles.admin,
			component: lazy(() => import('./order/Order'))
		},
		{
			path: '/apps/orders/orders',
			auth: authRoles.admin,
			component: lazy(() => import('./orders/Orders'))
		},
		{
			path: '/apps/orders',
			component: () => <Redirect to={authRoles.admin ? '/apps/orders/orders' : '/apps/orders/variants'} />
		}
	]
};

export default OrdersAppConfig;
