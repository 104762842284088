import { authRoles } from 'app/auth';
import { lazy } from 'react';

const AuthConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: '/login',
			component: lazy(() => import('./Login'))
		},
		{
			path: '/forgotpassword',
			component: lazy(() => import('./ForgotPassword'))
		},
		{
			path: '/newpwd',
			component: lazy(() => import('./ResetPassword'))
		}
	]
};

export default AuthConfig;
