import { FamilyMemberType, MemberType } from 'models/Member';

export const getEmail = (member: MemberType) => {
  if (!member || member.emailing === 0) return 'pas de mail utilisable';
  if (member.emailing === 1) return member.email_perso;
  return member.email_prof;
};

export const getName = (member: MemberType | FamilyMemberType): string =>
  member ? `${member.lastname} ${member.firstname}` : '';

export const getAddress = (member: MemberType) => {
  const country = (member.country || 'FRA') === 'FRA' ? '' : ` - ${member.country}`;
  return member.address ? `${member.address} - ${member.postal_code} ${member.city}${country}` : '';
};

const toUpperCaseSort = (a: string, b: string) => {
  const upperA = a.toUpperCase();
  const upperB = b.toUpperCase();
  if (upperA < upperB) return -1;
  if (upperA > upperB) return 1;
  return 0;
};

export const sortByName = (a: MemberType | FamilyMemberType, b: MemberType | FamilyMemberType) => {
  const result = toUpperCaseSort(a.lastname, b.lastname);
  return result || toUpperCaseSort(a.firstname, b.firstname);
};

export const sortMembers = (members: MemberType[]) => members.sort(sortByName);
