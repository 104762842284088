export const rowSpan = (arrayIn, name) => {
	let indexOfName = 0;
	const arrayOut = [];
	for (let index = 0; index < arrayIn.length; index += 1) {
		if (index > 0 && arrayIn[index][name] === arrayOut[indexOfName][name]) {
			arrayOut[index] = {
				...arrayIn[index],
				[name]: undefined
			};
			arrayOut[indexOfName].spanCount += 1;
		} else {
			indexOfName = index;
			arrayOut[index] = {
				...arrayIn[index],
				spanCount: 1
			};
		}
	}
	return arrayOut;
};
