import { lookupTeamStatus } from "constant/lookups";

export const groupByProduct = variants => {
	return variants.reduce((acc, curr, index) => {
		const productId = acc.findIndex(item => item.name === curr.product_name);
		if (productId === -1) {
			acc.push({ name: curr.product_name, variants: [curr.name] });
		} else {
			acc[productId].variants.push(curr.name);
		}
		return acc;
	}, []);
};

export const getProductFromVariant = (products, variantId) => {
	let productId;
	products.forEach(product => {
		const variant = product.variants.find(item => item.id === variantId);
		if (variant && !productId) {
			productId = product.id;
		}
	});
	return productId;
};

export const displayStatus = status =>
	status
		? status
				.split(',')
				.map(item => lookupTeamStatus[item])
				.join(', ')
		: '';
