import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import jwtService from 'app/services/jwtService';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { hideMessage, showMessage } from 'app/store/fuse/messageSlice';
import { updateMember } from 'app/main/apps/secretariat/store/memberSlice';
import { getEmail } from 'helpers';

import { setUserData, logoutUser } from './store/userSlice';

class Auth extends Component {
  state = {
    waitAuthCheck: true,
  };

  componentDidMount() {
    if (window.location.href.indexOf('pwbridge') === -1) {
      return this.jwtCheck().then(() => {
        this.setState({ waitAuthCheck: false });
      });
    }
    if (window.addEventListener) {
      window.addEventListener('message', this.getMessage, true);
    } else {
      window.attachEvent('message', this.getMessage);
    }
    window.parent.postMessage('ready', '*');
    return true;
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.getMessage);
  }

  getMessage = (e) => {
    if (e.origin && e.data && e.data.code) {
      const user = e.data;
      jwtService
        .signInWithExternalId(
          getEmail(user),
          user.code,
          `${user.firstname} ${user.lastname}`,
          e.origin,
        )
        .then((userResponse) => {
          this.props.setUserData(userResponse);
          updateMember({ ...user, id: userResponse.id });
        })
        .then(() => {
          this.setState({ waitAuthCheck: false });
          this.props.children.props.history.push('/apps/orders/orderWizard');
        });
    }
  };

  jwtCheck = () =>
    new Promise((resolve) => {
      jwtService.on('onAutoLogin', () => {
        // this.props.showMessage({ message: 'Logging in with JWT' });

        /**
         * Sign in and retrieve user data from Api
         */
        jwtService
          .signInWithToken()
          .then((user) => {
            this.props.setUserData(user);

            resolve();

            // this.props.showMessage({ message: 'Logged in with JWT' });
          })
          .catch((error) => {
            this.props.showMessage({ message: error });

            resolve();
          });
      });

      jwtService.on('onAutoLogout', (message) => {
        if (message) {
          this.props.showMessage({ message });
        }

        this.props.logout();

        resolve();
      });

      jwtService.on('onNoAccessToken', () => {
        resolve();
      });

      jwtService.init();

      return Promise.resolve();
    });

  render() {
    return this.state.waitAuthCheck ? <FuseSplashScreen /> : <>{this.props.children}</>;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: logoutUser,
      setUserData,
      showMessage,
      hideMessage,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(Auth);
