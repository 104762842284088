import { authRoles } from 'app/auth';
import lexicon from 'constant/lexicon';
import i18next from 'i18next';
import en from './navigation-i18n/en';
import fr from './navigation-i18n/fr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('fr', 'navigation', fr);

const navigationConfig = [
	{
		id: 'dashboards',
		title: 'Tableau de bord',
		type: 'item',
		auth: authRoles.staff,
		icon: 'dashboard',
		url: '/apps/dashboards'
	},
	{
		id: 'profile',
		title: lexicon('my_account'),
		type: 'collapse',
		auth: authRoles.user,
		icon: 'account_circle',
		children: [
			{
				id: 'account',
				title: lexicon('my_data'),
				type: 'item',
				auth: authRoles.user,
				icon: 'account_circle',
				url: '/pages/account',
				exact: true
			},
			{
				id: 'contracts',
				title: lexicon('contracts'),
				type: 'item',
				auth: authRoles.staff,
				icon: 'history_edu',
				url: '/pages/contracts',
				exact: true
			},
			{
				id: 'orders',
				title: lexicon('orders'),
				type: 'item',
				auth: authRoles.user,
				icon: 'collections',
				url: '/pages/orders',
				exact: true
			},
			{
				id: 'messages',
				title: 'Messages',
				type: 'item',
				auth: authRoles.user,
				icon: 'mail',
				url: '/pages/messages',
				exact: true
			}
		]
	},
	{
		id: 'applications',
		title: lexicon('secretariat'),
		type: 'collapse',
		auth: authRoles.staff,
		icon: 'apps',
		children: [
			{
				id: 'secretariat-members',
				title: lexicon('member'),
				type: 'item',
				auth: authRoles.staff,
				icon: 'people',
				url: '/apps/secretariat/members',
				exact: true
			},
			{
				id: 'secretariat-team',
				title: lexicon('team'),
				type: 'item',
				auth: authRoles.staff,
				icon: 'face',
				url: '/apps/secretariat/members/team',
				exact: true
			},
			{
				id: 'secretariat-office',
				title: lexicon('office'),
				type: 'item',
				auth: authRoles.staff,
				icon: 'person',
				url: '/apps/secretariat/members/office',
				exact: true
			},
			{
				id: 'orders-products',
				title: lexicon('products'),
				type: 'item',
				auth: authRoles.staff,
				icon: 'beach_access',
				url: '/apps/secretariat/products',
				exact: true
			},
			{
				id: 'secretariat-print',
				title: 'Impressions',
				type: 'item',
				auth: authRoles.staff,
				icon: 'print',
				url: '/apps/secretariat/print',
				exact: true
			},
			{
				id: 'file-manager',
				title: 'Gestion de fichier',
				translate: 'FILE_MANAGER',
				type: 'item',
				auth: authRoles.admin,
				icon: 'folder',
				url: '/apps/file-manager'
			},
			// {
			//   id: 'mail',
			//   title: 'Mail',
			//   translate: 'MAIL',
			//   type: 'item',
			//   icon: 'email',
			//   url: '/apps/mail',
			//   // badge: {
			//   //   title: 25,
			//   //   bg: '#F44336',
			//   //   fg: '#FFFFFF'
			//   // }
			// },
			{
				id: 'secretariat-parameters',
				title: lexicon('parameters'),
				type: 'item',
				auth: authRoles.admin,
				icon: 'settings',
				url: '/apps/secretariat/parameters',
				exact: true
			}
		]
	},
	{
		id: 'orders',
		title: lexicon('orderManagement'),
		type: 'collapse',
		auth: authRoles.staff,
		icon: 'shopping_cart',
		url: '/apps/orders',
		children: [
			{
				id: 'orders-variants',
				title: `${lexicon('orders')} par ${lexicon('product')}`,
				type: 'item',
				auth: authRoles.staff,
				icon: 'collections',
				url: '/apps/orders/variants',
				exact: true
			},
			{
				id: 'orders-orders',
				title: `${lexicon('orders')} par ${lexicon('member')}`,
				type: 'item',
				auth: authRoles.admin,
				icon: 'shopping_basket',
				url: '/apps/orders/orders',
				exact: true
			},
			{
				id: 'orders-orderWizard',
				title: `Nouvelle ${lexicon('order')}`,
				type: 'item',
				auth: authRoles.admin,
				icon: 'add_shopping_cart',
				url: '/apps/orders/orderWizard',
				exact: true
			}
		]
	},
	{
		id: 'accounting',
		title: lexicon('accounting'),
		type: 'collapse',
		auth: authRoles.admin,
		icon: 'money',
		children: [
			{
				id: 'accounting-payments',
				title: lexicon('payments'),
				type: 'item',
				auth: authRoles.admin,
				icon: 'payment',
				url: '/apps/accounting/payments',
				exact: true
			}
		]
	}
];

export default navigationConfig;
