import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { apiUrl } from 'helpers/record';
/* eslint-disable camelcase */

const EXPIRED = 'Connexion expirée, merci de vous reconnecter';
const JWT_ACCESS_TOKEN = 'jwt_access_token';

class JwtService extends FuseUtils.EventEmitter {
	init() {
		this.setInterceptors();
		this.handleAuthentication();
	}

	setInterceptors = () => {
		axios.interceptors.response.use(
			response => {
				return response;
			},
			err => {
				return new Promise((resolve, reject) => {
					if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
						// if you ever get an unauthorized response, logout the user
						this.emit('onAutoLogout', 'Merci de vous connecter');
						this.setSession(null);
					}
					throw err.response.data.error || err.response.statusText;
				});
			}
		);
	};

	handleAuthentication = () => {
		const access_token = this.getAccessToken();

		if (!access_token) {
			this.emit('onNoAccessToken');

			return;
		}

		if (this.isAuthTokenValid(access_token)) {
			this.setSession(access_token);
			this.emit('onAutoLogin', true);
		} else {
			this.setSession(null);
			this.emit('onAutoLogout', EXPIRED);
		}
	};

	createUser = data => {
		return new Promise((resolve, reject) => {
			axios.defaults.headers.common['X-Group'] = process.env.REACT_APP_GROUP;
			axios.post(apiUrl('/auth/register'), data).then(response => {
				if (response.data.data) {
					this.setSession(response.data.access_token);
					resolve(response.data.data);
				} else {
					reject(response.data.error);
				}
			});
		});
	};

	signInWithEmailAndPassword = (email, password) =>
		new Promise((resolve, reject) => {
			axios.defaults.headers.common['X-Group'] = process.env.REACT_APP_GROUP;
			axios
				.post(apiUrl('auth'), {
					email,
					password
				})
				.then(response => {
					if (response.data.user) {
						this.setSession(response.data.access_token);
						resolve(response.data.user);
					} else {
						reject(response.data.errors);
					}
				})
				.catch(error => {
					reject(error);
				});
		});

	signInWithExternalId = (email, code, display_name, origin) =>
		new Promise((resolve, reject) => {
			axios.defaults.headers.common['X-Group'] = process.env.REACT_APP_GROUP;
			axios
				.post(apiUrl('/auth/external'), {
					email,
					code,
					display_name,
					origin
				})
				.then(response => {
					if (response.data.user) {
						this.setSession(response.data.access_token);
						resolve(response.data.user);
					} else {
						reject(response.data.error);
					}
				})
				.catch(error => {
					reject(error);
				});
		});

	signInWithToken = () => {
		return new Promise((resolve, reject) => {
			axios.defaults.headers.common['X-Group'] = process.env.REACT_APP_GROUP;
			axios
				.get(apiUrl('auth/access_token'), {
					data: {
						access_token: this.getAccessToken()
					}
				})
				.then(response => {
					if (response.data) {
						this.setSession(response.data.access_token);
						resolve(response.data.user);
					} else {
						this.logout();
						reject(response.errors);
					}
				})
				.catch(error => {
					this.logout();
					reject(EXPIRED);
				});
		});
	};

	updateUserData = user =>
		user.id ? axios.put(apiUrl(`auth/user/${user.id}`), { user }) : axios.post(apiUrl('auth/user'), { user });

	setSession = access_token => {
		if (access_token) {
			const bearer = 'Bearer ';
			const jwtAccessToken =
				access_token.indexOf(bearer) === 0 ? access_token.slice(bearer.length) : access_token;
			localStorage.setItem(JWT_ACCESS_TOKEN, jwtAccessToken);
			axios.defaults.headers.common['X-Authorization'] = `${bearer}${jwtAccessToken}`;
			axios.defaults.headers.common['X-Group'] = process.env.REACT_APP_GROUP;
		} else {
			localStorage.removeItem(JWT_ACCESS_TOKEN);
			delete axios.defaults.headers.common['X-Authorization'];
		}
	};

	logout = () => {
		axios.get(apiUrl('auth/logout'));
		this.setSession(null);
	};

	isAuthTokenValid = access_token => {
		if (!access_token) {
			return false;
		}
		const decoded = jwtDecode(access_token);
		const currentTime = Date.now() / 1000;
		if (decoded.exp < currentTime) {
			console.warn('access token expired');
			return false;
		}

		return true;
	};

	getAccessToken = () => {
		return window.localStorage.getItem(JWT_ACCESS_TOKEN);
	};
}

const instance = new JwtService();

export default instance;
