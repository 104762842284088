import Moment from 'moment';
import 'moment/locale/fr';
import { ProductType } from 'models/Products';
import { VariantType } from 'models/Variants';

const LOCALE = 'fr';

export const dateTimeHTML = () => {
  const dateTime = Moment(new Date());
  return `${dateTime.format(Moment.HTML5_FMT.DATE)} ${dateTime.format(
    Moment.HTML5_FMT.TIME_SECONDS,
  )}`;
};

export const calculateAge = (birthdate: string, eventdate: string) => {
  const ageDifMs = new Date(eventdate).getTime() - new Date(birthdate).getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  const age = Math.abs(ageDate.getUTCFullYear() - 1970);
  return Number.isNaN(age) ? 999 : age;
};

export const calculateAgeLong = (birthdate: string, eventdate: string) => {
  const age = calculateAge(birthdate, eventdate);
  return `${age} an${age === 1 ? '' : 's'}`;
};

export const isValidDate = (date: string, template?: string) => {
  const mDate = template ? Moment(date, template) : Moment(date);
  return mDate.isValid();
};

export const formatDateTemplate = (targetTemplate: string, inputDate?: string, template?: string) => {
  const date = inputDate ?? new Date();
  const mDate = template ? Moment(date, template) : Moment(date);
  if (mDate.isValid()) {
    return mDate.locale(LOCALE).format(targetTemplate);
  }
  return '';
};

export const formatDate = (date?: string) => formatDateTemplate('DD/MM/YYYY', date);

export const formatDateWeek = (date?: string) => formatDateTemplate('ddd D/MM', date);

export const formatDateLong = (date?: string) => formatDateTemplate('DD MMM YYYY', date);

export const formatDateTimeLong = (date?: string) => formatDateTemplate('D MMMM YYYY HH:mm', date);

export const formatDateRange = (dateFrom: string, dateTo: string) =>
  `du ${formatDateTemplate('D MMM', dateFrom)} au ${formatDateTemplate('D MMM YYYY', dateTo)}`;

export const formatDatePicker = (date?: string) => formatDateTemplate('YYYY-MM-DD', date);

export const formatDateTime = (date?: string) => formatDateTemplate('DD/MM/YYYY HH:mm:ss', date);

export const formatDateTimePicker = (date?: string) =>
  formatDateTemplate('YYYY-MM-DD HH:mm:ss', date);

export const todayDateLong = () => formatDateTemplate('D MMMM YYYY');

export const formatSaveDate = (date?: string) => formatDateTemplate(Moment.HTML5_FMT.DATE, date);

export const formatDateFromTo = (item: ProductType | VariantType) =>
  `du ${formatDateWeek(item.date_from)} au ${formatDateWeek(item.date_to)}`;

export const encodeDateUrl = (date?: string) => formatDateTemplate('YYYYMMDDHHmmss', date);

export const decodeDateUrl = (date?: string) =>
  formatDateTemplate('YYYY-MM-DD HH:mm:ss', date, 'YYYYMMDDHHmmss');

export const isBirthdayInVariant = (date: string, from: string, to: string) => {
  const dmBirthday = formatDateTemplate('MMDD', date);
  if (dmBirthday.length === 0) return false;

  const dmFrom = Moment(from).local(true).format('MMDD');
  const dmTo = Moment(to).local(true).format('MMDD');
  return dmBirthday >= dmFrom && dmBirthday <= dmTo;
};
