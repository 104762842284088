const locale = {
	APP_TITLE: 'Mails',
	COMPOSE: 'Nouveau message',
	FOLDERS: 'Dossiers',
	FILTERS: 'Filtres',
	LABELS: 'Libellés',
	NO_MESSAGES: "Il n'y a pas de messages!",
	SEARCH_PLACEHOLDER: 'Rechercher pour un eMail ou un contact',
	INBOX: 'Boite de réception',
	SENT: 'Envoyé',
	DRAFTS: 'Brouillons',
	SPAM: 'Spam',
	TRASH: 'Corbeille',
	STARRED: 'Message suivi',
	IMPORTANT: 'Important'
};

export default locale;
