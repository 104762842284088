export const STATUS_NEW = 0;
export const STATUS_WAITING = 1;
export const STATUS_CONTRIBUTION = 2;
export const STATUS_DEPOSIT_PAID = 3;
export const STATUS_ACK_SENT = 4;
export const STATUS_INFORMATION_SENT = 5;
export const STATUS_ORDER_PAID = 10;
export const STATUS_REFUND = 11;
export const STATUS_INVOICE = 12;
export const STATUS_PAIEMENT_ERROR = 20;
export const STATUS_PRE_ORDER = 30;
export const STATUS_CANCELED = 90;
export const STATUS_CLOSED = 91;

export const ORDER_STATUSES = [
	{
		id: STATUS_NEW,
		name: 'Nouveau',
		color: 'bg-grey text-white'
	},
	{
		id: STATUS_WAITING,
		name: "En attente d'acompte",
		color: 'bg-blue text-white'
	},
	{
		id: STATUS_PRE_ORDER,
		name: "En pré-inscription",
		color: 'bg-blue text-white'
	},
	{
		id: STATUS_CONTRIBUTION,
		name: 'A jour de cotisation',
		color: 'bg-green-300 text-white'
	},
	{
		id: STATUS_DEPOSIT_PAID,
		name: 'Acompte payé',
		color: 'bg-green text-white'
	},
	{
		id: STATUS_ACK_SENT,
		name: 'Accusé de réception envoyé',
		color: 'bg-orange-200 text-black'
	},
	{
		id: STATUS_INFORMATION_SENT,
		name: 'Information dossier envoyée',
		color: 'bg-orange text-black'
	},
	{
		id: STATUS_ORDER_PAID,
		name: 'Dossier complet',
		color: 'bg-green-700 text-white'
	},
	{
		id: STATUS_REFUND,
		name: 'Remboursé',
		color: 'bg-green-300 text-white'
	},
	{
		id: STATUS_INVOICE,
		name: 'Facture éditée',
		color: 'bg-grey-500 text-white'
	},
	{
		id: STATUS_PAIEMENT_ERROR,
		name: 'Erreur lors du paiement',
		color: 'bg-red-700 text-white'
	},
	{
		id: STATUS_CANCELED,
		name: 'Annulé',
		color: 'bg-grey text-white'
	},
	{
		id: STATUS_CLOSED,
		name: 'Clôturée',
		color: 'bg-grey-800 text-white'
	}
];

export const ATTACHMENT_STATUSES = [
	{
		id: '',
		name: '',
		color: ''
	},
	{
		id: 'new-order',
		name: 'Nouveau',
		color: 'bg-orange text-white'
	},
	{
		id: 'new',
		name: 'Nouveau document',
		color: 'bg-orange text-white'
	},
	{
		id: 'read',
		name: 'En cour de vérification',
		color: 'bg-blue text-white'
	},
	{
		id: 'uncomplete',
		name: 'Incomplet',
		color: 'bg-red-700 text-white'
	},
	{
		id: 'validated',
		name: 'Validé',
		color: 'bg-green-200 text-black'
	},
	{
		id: 'complete',
		name: 'Complet',
		color: 'bg-green text-white'
	},
	{
		id: 'cancel',
		name: 'Annulé',
		color: 'bg-grey text-white'
	},
	{
		id: 'waiting',
		name: "Liste d'attente",
		color: 'bg-red-300 text-black'
	}
];

export const ORDER_DETAIL_STATUSES = [
	{
		id: 'new-order',
		name: 'Nouveau',
	},
	{
		id: 'pre-order',
		name: 'Pré-inscription',
	},
	{
		id: 'waiting',
		name: 'Liste d\'attente',
	},
	{
		id: 'cancel',
		name: 'Annulé',
	},
	{
		id: 'complete',
		name: 'complété',
	},
	{
		id: 'closed',
		name: 'Clôturé',
	},
	{
		id: 'validated',
		name: 'Validé',
	},
];