import FuseUtils from '@fuse/utils';
import { authRoles } from 'app/auth';
import AppsConfigs from 'app/main/apps/appsConfigs';
import AuthConfig from 'app/main/auth/AuthConfig';
import LogoutConfig from 'app/main/logout/LogoutConfig';
import pagesConfigs from 'app/main/pages/pagesConfigs';
import { Redirect } from 'react-router-dom';

const routeConfigs = [...AppsConfigs, LogoutConfig];

const routes = [
	...FuseUtils.generateRoutesFromConfigs(routeConfigs, authRoles.staff),
	...FuseUtils.generateRoutesFromConfigs(pagesConfigs, authRoles.user),
	...FuseUtils.generateRoutesFromConfigs([AuthConfig]),
	{
		path: '/',
		exact: true,
		component: () => <Redirect to="/pages/account" />
	},
	{
		component: () => <Redirect to="/pages/account" />
	}
];

export default routes;
