let main = 'visage';
if (process.env.REACT_APP_ENV === 'TEST') {
	main = 'light6';
}
if (process.env.REACT_APP_ENV === 'DEV') {
	main = 'light5';
}

const settingsConfig = {
	layout: {
		style: 'layout', // layout1 layout2 layout3
		config: {} // checkout default layout configs at app/fuse-layouts for example  app/fuse-layouts/layout1/Layout1Config.js
	},
	customScrollbars: true,
	direction: 'ltr', // rtl, ltr
	theme: {
		main,
		navbar: 'greyDark',
		toolbar: 'mainThemeLight',
		footer: 'mainThemeDark'
	}
};

export default settingsConfig;
