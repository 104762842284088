import { createSlice } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import jwtService from 'app/services/jwtService';
import { setUserData } from './userSlice';

export const submitLogin = ({ email, password }) => async dispatch => {
	return jwtService
		.signInWithEmailAndPassword(email, password)
		.then(user => {
			dispatch(setUserData(user));
			return dispatch(loginSuccess());
		})
		.catch(errors => {
			return dispatch(loginError(errors));
		});
};

export function submitLoginExternal(user, origin) {
	const email = user.contacts.find(contact => contact.type === 'email' && contact.favorite === true).value;
	const displayName = `${user.firstname} ${user.lastname}`;

	return dispatch =>
		jwtService
			.signInWithExternalId(email, user.external_id, displayName, origin)
			// .then((userResponse) => {
			//   const member = {
			//     ...user,
			//     id: userResponse.uuid
			//   };
			//   dispatch(saveMember(member));
			//
			//   return userResponse;
			// })
			.then(userResponse => {
				return dispatch(loginSuccess());
			})
			.catch(error => {
				return dispatch(loginError(error));
			});
}

const initialState = {
	success: false,
	errors: []
};

const loginSlice = createSlice({
	name: 'auth/login',
	initialState,
	reducers: {
		loginSuccess: (state, action) => {
			state.success = true;
			state.errors = [];
		},
		loginError: (state, action) => {
			state.success = false;
			state.errors = action.payload;
		}
	},
	extraReducers: {}
});

export const { loginSuccess, loginError } = loginSlice.actions;

export default loginSlice.reducer;
