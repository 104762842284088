export const formatter = new Intl.NumberFormat('fr-FR', {
	style: 'currency',
	currency: 'EUR',
	minimumFractionDigits: 2,
});

export const getNumericBoolean = (value) => {
	if (typeof value === 'string') {
		return value !== '0' ? 1 : 0;
	}
	if (typeof value === 'number') {
		return value !== 0 ? 1 : 0;
	}
	if (typeof value === 'boolean') {
		return value ? 1 : 0;
	}
	return 0;
};

export const getBoolean = (value) => {
	if (typeof value === 'string') {
		return value !== '0';
	}
	if (typeof value === 'number') {
		return value !== 0;
	}
	if (typeof value === 'boolean') {
		return value;
	}
	return false;
};

export const formatBytes = (size: number | string, decimals: number = 2) => {
	if (Number.isNaN(size) || size === '') return '-';

	const sizeNum = typeof size === 'string' ? parseFloat(size) : size;
	if (sizeNum === 0) return '0 o';

	const c = decimals < 0 ? 0 : decimals;
	const d = Math.floor(Math.log(sizeNum) / Math.log(1024));
	const value = (sizeNum / 1024 ** d).toFixed(c);
	return `${value} ${['o', 'ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'][d]}`;
};

export const getCurrentFolder = (path: string = '', directorySeparator: string = '/') => {
	const pathes = path.split(directorySeparator);
	return pathes.length === 0 ? '' : pathes[pathes.length - 1];
};

export const getUpperPath = (path: string = '', directorySeparator: string = '/') => {
	const pathes = path.split(directorySeparator);
	if (pathes.length === 0) return '';

	pathes.pop();
	return pathes.join(directorySeparator);
};
