import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import { apiUrl, cleanRecord } from 'helpers/record';

const urlAuth = apiUrl('auth/newpwd');

export const getNewpwd = ({ email, url }) => async dispatch => {
	const path = `email=${encodeURIComponent(email)}&url=${encodeURIComponent(url)}`;
	const request = axios.get(`${urlAuth}?${path}`);

	return request
		.then(response => {
			if (response.data.status) {
				dispatch(
					showMessage({
						message: response.data.message,
						autoHideDuration: 5000,
						variant: 'success'
					})
				);
				return dispatch(newpwdSuccess({ ...response.data, email, action: 'request' }));
			}
			return dispatch(newpwdError(response.data.errors));
		})
		.catch(error => {
			dispatch(showMessage({ message: error, autoHideDuration: 10000, variant: 'error' }));
			return dispatch(newpwdError(error));
		});
};

export const submitNewpwd = data => async dispatch => {
	const request = axios.post(urlAuth, cleanRecord(data));

	return request
		.then(response => {
			if (response.data.status) {
				dispatch(
					showMessage({
						message: response.data.message,
						autoHideDuration: 5000,
						variant: 'success'
					})
				);
				return dispatch(newpwdSuccess({ ...response.data, email: data.email, action: 'update' }));
			}
			return dispatch(newpwdError(response.data.errors));
		})
		.catch(error => {
			dispatch(showMessage({ message: error, autoHideDuration: 10000, variant: 'error' }));
			return dispatch(newpwdError(error));
		});
};

const initialState = {
	success: false,
	result: {},
	errors: []
};

const newpwdSlice = createSlice({
	name: 'auth/newpwd',
	initialState,
	reducers: {
		newpwdSuccess: (state, action) => {
			state.success = true;
			state.result = action.payload;
			state.errors = [];
		},
		newpwdError: (state, action) => {
			state.success = false;
			state.errors = action.payload;
		}
	}
});

export const { newpwdSuccess, newpwdError } = newpwdSlice.actions;

export default newpwdSlice.reducer;
