import { createSlice } from '@reduxjs/toolkit';
import history from '@history';
import _ from '@lodash';
import { setDefaultSettings } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import jwtService from 'app/services/jwtService';
import { isAdmin, isStaff } from 'helpers/auth';

export const setUserData = (user) => async (dispatch, getState) => {
  history.location.state = {
    redirectUrl:
      user.redirectUrl ||
      (isAdmin(user.role) || isStaff(user.role) ? 'apps/dashboards' : 'pages/orders'),
  };
  dispatch(setDefaultSettings(user.data.settings));
  user.isAdmin = isAdmin(user.role);
  user.isStaff = isStaff(user.role);
  dispatch(setUser(user));
};

export const updateUserSettings = (settings) => async (dispatch, getState) => {
  const oldUser = getState().auth.user;
  const user = _.merge({}, oldUser, { data: { settings } });

  dispatch(updateUserData(user));

  return dispatch(setUserData(user));
};

export const updateUserShortcuts = (shortcuts) => async (dispatch, getState) => {
  const { user } = getState().auth;
  const newUser = {
    ...user,
    data: {
      ...user.data,
      shortcuts,
    },
  };

  dispatch(updateUserData(newUser));

  return dispatch(setUserData(newUser));
};

export const logoutUser = () => async (dispatch, getState) => {
  const { user } = getState().auth;

  if (!user.role || user.role.length === 0) {
    // is guest
    return null;
  }

  history.push({
    pathname: '/',
  });

  jwtService.logout();

  // dispatch(setInitialSettings());

  return dispatch(userLoggedOut());
};

export const updateUserData = (user) => async (dispatch, getState) => {
  if (!user.role || user.role.length === 0) {
    // is guest
    return;
  }
  jwtService
    .updateUserData(user)
    // .then(() => {
    // 	dispatch(showMessage({ message: 'Données utilisateur sauvegardées' }));
    // })
    .catch((error) => {
      dispatch(showMessage({ message: error.message }));
    });
};

const initialState = {
  role: [], // guest
  data: {
    displayName: '',
    photoURL: '',
    email: '',
    shortcuts: [],
    settings: {},
  },
};

const userSlice = createSlice({
  name: 'auth/user',
  initialState,
  reducers: {
    setUser: (state, action) => action.payload,
    updateLocalUserData: (state, action) => {
      state.data.email = action.payload.email;
      state.data.displayName = action.payload.displayName;
    },
    userLoggedOut: (state, action) => initialState,
  },
  extraReducers: {},
});

export const { setUser, updateLocalUserData, userLoggedOut } = userSlice.actions;

export default userSlice.reducer;
