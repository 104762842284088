import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logoutUser } from 'app/auth/store/userSlice';
import { lookupRole } from 'constant';
import { isStaff } from 'helpers';
import lexicon from 'constant/lexicon';

const UserMenu = () => {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);

	const [userMenu, setUserMenu] = useState(null);

	const userMenuClick = event => {
		setUserMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setUserMenu(null);
	};

	return (
		<>
			<Button className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6" onClick={userMenuClick}>
				<div className="hidden md:flex flex-col mx-4 items-end">
					<Typography component="span" className="font-semibold flex">
						{user.data.displayName}
					</Typography>
					<Typography className="text-11 font-medium capitalize" color="textSecondary">
						{lookupRole[user.role.toString()]}
					</Typography>
				</div>

				<Icon className="text-16 hidden sm:flex" variant="action">
					keyboard_arrow_down
				</Icon>
			</Button>

			<Popover
				open={Boolean(userMenu)}
				anchorEl={userMenu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				classes={{
					paper: 'py-8'
				}}
			>
				{!user.role || user.role.length === 0 ? (
					<>
						<MenuItem component={Link} to="/login" role="button">
							<ListItemIcon className="min-w-40">
								<Icon>lock</Icon>
							</ListItemIcon>
							<ListItemText primary="Se connecter" />
						</MenuItem>
						<MenuItem component={Link} to="/register" role="button">
							<ListItemIcon className="min-w-40">
								<Icon>person_add</Icon>
							</ListItemIcon>
							<ListItemText primary="Créer un compte" />
						</MenuItem>
					</>
				) : (
					<>
						<MenuItem component={Link} to="/pages/messages" onClick={userMenuClose} role="button">
							<ListItemIcon className="min-w-40">
								<Icon>mail</Icon>
							</ListItemIcon>
							<ListItemText primary="Messages" />
						</MenuItem>
						{isStaff(user.role) && (
							<MenuItem component={Link} to="/pages/contract" onClick={userMenuClose} role="button">
								<ListItemIcon className="min-w-40">
									<Icon>history_edu</Icon>
								</ListItemIcon>
								<ListItemText primary={lexicon('contracts')} />
							</MenuItem>
						)}
						<MenuItem component={Link} to="/pages/orders" onClick={userMenuClose} role="button">
							<ListItemIcon className="min-w-40">
								<Icon>collections</Icon>
							</ListItemIcon>
							<ListItemText primary={lexicon('orders')} />
						</MenuItem>
						<MenuItem component={Link} to="/pages/account" onClick={userMenuClose} role="button">
							<ListItemIcon className="min-w-40">
								<Icon>account_circle</Icon>
							</ListItemIcon>
							<ListItemText primary={lexicon('my_data')} />
						</MenuItem>
						<MenuItem
							onClick={() => {
								dispatch(logoutUser());
								userMenuClose();
							}}
						>
							<ListItemIcon className="min-w-40">
								<Icon>exit_to_app</Icon>
							</ListItemIcon>
							<ListItemText primary="Se déconnecter" />
						</MenuItem>
					</>
				)}
			</Popover>
		</>
	);
};

export default UserMenu;
