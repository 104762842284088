import { ApiQuery, Query, Sort } from 'models/Ressource';

export const randString = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const randNumber = (length: number) =>
  [...Array(length)].map((_) => Math.trunc(Math.random() * 10).toString(10)).join('');

export const updateListWithItem = (list: any[], item: any, key = 'id') => {
  let newList = [];
  if (item.action === 'delete') {
    newList = list.filter((row) => !(key in row && key in item.row && row[key] === item.row[key]));
  } else {
    newList = [...list];
    const index = newList.findIndex(
      (row) => key in row && key in item.row && row[key] === item.row[key],
    );
    if (index === -1) {
      newList.push(item.row);
    } else {
      newList[index] = item.row;
    }
  }
  return newList;
};

export const apiUrl = (ressource?: string, path?: string) => {
  const apiRessource = ressource ? `/${ressource}` : '';
  const apiPath = path ? `${apiRessource.endsWith('/') ? '' : '/'}${path}` : '';
  return `${process.env.REACT_APP_BASE_API}${apiRessource}${apiPath}`;
};

export const cleanRecord = (data: { [key: string]: any }): { [key: string]: any } =>
  Object.fromEntries(
    Object.keys(data)
      .filter(
        (key) => !key.startsWith('@') && !['created_at', 'updated_at', 'updated_by'].includes(key),
      )
      .map((item) => [item, data[item]]),
  );

export const buildSort = (order: Sort[] = []) => {
  const result: ApiQuery = {};
  if (order?.length > 0) {
    const orderAsc = order.filter((item) => item.direction !== 'desc').map((item) => item.id);
    if (orderAsc.length > 0) {
      result.order = orderAsc.join(',');
    }
    const orderDesc = order.filter((item) => item.direction === 'desc').map((item) => item.id);
    if (orderDesc.length > 0) {
      result.order_desc = orderDesc.join(',');
    }
  }
  return result;
};

export const buildRequest = ({ filter, fields, page, rowsPerPage, sort }: Query) => {
  let requestParams: ApiQuery = {};
  if (filter) {
    Object.keys(filter)
      .filter((param) => filter[param] || ['!', '<', '>', '='].includes(param.slice(-1)))
      .forEach((param) => {
        if (filter[param] !== '*') {
          const filterValue = filter[param];
          requestParams[param] =
            typeof filterValue === 'string' ? filterValue.trim() : filterValue.toString();
        }
      });
  }
  if (fields) {
    requestParams.fields = typeof fields === 'string' ? fields : fields.join(',');
  }
  if (page !== undefined) {
    requestParams.page = `${page}${!rowsPerPage ? '' : `,${rowsPerPage}`}`;
  }
  if (sort?.length > 0) {
    requestParams = { ...requestParams, ...buildSort(sort) };
  }
  const requestPath = Object.entries(requestParams)
    .filter((entry) => !!entry[1])
    .map((entry) => entry.join(['!', '<', '>', '='].includes(entry[1][0]) ? '' : '='))
    .join('&');
  return requestPath ? `?${requestPath}` : '';
};

export const isTemporaryId = (value?: string) => {
  const regexId = /^[a-z-]*-.*/;
  return !value || regexId.test(value);
};
