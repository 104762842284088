const lexicons = {
	accounting: 'Comptabilité',
	address: 'Adresse',
	birthdate: 'Date de naissance',
	city: 'Ville',
	contribution: 'Cotisation',
	contract: 'Contrat',
	contracts: 'Contrats',
	country: 'Pays',
	email_perso: 'Email Personnel',
	email_prof: 'Email Professionel',
	emailing: 'eMail préféré',
	firstname: 'Prénom',
	gender: 'Genre',
	lastname: 'Nom',
	member: 'Adhérent',
	members: 'Adhérents',
	mobile: 'Téléphone portable',
	my_account: 'Mon compte',
	my_data: 'Mes informations',
	my_orders: 'Mes inscriptions',
	office: 'Bureau',
	order: 'Inscription',
	orderManagement: 'Gestion des inscriptions',
	orders: 'Inscriptions',
	parameters: 'Paramètres',
	payment: 'Paiement',
	payments: 'Paiements',
	phone: 'Téléphone',
	postal_code: 'Code postal',
	product: 'Séjour',
	products: 'Séjours',
	secretariat: 'Secrétariat',
	society: 'Association',
	team: 'Encadrement',
	team_member: 'Encadrant•e',
	type: 'Type',
	variant: 'Variante',
	variants: 'Variantes',
	user: 'Adhérent',
	staff: 'Membre actif',
	admin: 'Administrateur',
	status: 'Statut',
	method: 'Méthode'
};

const lexicon = value => lexicons[value] || value;

export default lexicon;
