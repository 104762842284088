import lexicon from 'constant/lexicon';

export const lookupRole = {
	user: lexicon('user'),
	staff: lexicon('staff'),
	admin: lexicon('admin')
};

export const lookupType = {
	child: 'Enfant',
	partner: 'Conjoint',
	parent: 'Parent',
	tutor: 'Tuteur',
	father: 'Père',
	mother: 'Mère',
	grand_father: 'Grand-Père',
	grand_mother: 'Grand-Mère'
};

export const lookupMail = {
	0: 'Refuse les emails',
	1: 'privilégier email personnel',
	2: 'privilégier email professionnel'
};

export const lookupGender = {
	'-': '-',
	M: 'Masculin',
	F: 'Feminin'
};

export const lookupGenderChild = {
	'-': '-',
	M: 'Garçon',
	F: 'Fille'
};

export const lookupCategory = {
	stay: 'Séjour',
	contribution: 'Cotisation',
	activity: 'Activité',
	goods: 'Produit'
};

export const lookupVariantType = {
	period: 'Hébergement',
	option: 'Option',
	member: lexicon('member')
};

export const lookupPaymentStatus = {
	new: 'Nouveau',
	deleted: 'Annulé',
	delivered: 'Remis en Banque',
	delay: 'Différé',
	requires_payment_method: 'Paiement requis',
	requires_confirmation: 'Attente confirmation',
	requires_action: 'Attente action',
	processing: 'Traitement en cours',
	succeeded: 'Paiement validé',
	refused: 'Paiement refusé',
	['']: 'Non finalisé',
};

export const lookupPaymentType = {
	deposit: 'Acompte',
	balance: 'Reste à régler',
	total: 'Montant total',
	partial: 'Règlement partiel',
	contribution: 'Cotisation'
};

export const lookupPaymentMode = {
	check: 'Chèque',
	card: 'Carte bancaire',
	transfer: 'Virement',
	cash: 'Espèces',
	other: 'Autre (bon CAF, Chèque vacances...)'
};

export const lookupTeamStatus = {
	leader: 'Directeur',
	animator: 'Animateur',
	health_assistant: 'Assistant sanitaire',
	leader_assistant: 'Directeur adjoint',
	lifeguard: 'Surveillant de baignade'
};

export const lookupAttachmentStatus = {
	new: 'Nouveau',
	read: 'En cours de vérification',
	validated: 'Validé',
	uncomplete: 'Incomplet',
	complete: 'Complet',
	cancel: 'Annulé'
};
