import { lazy } from 'react';

const ProfilePageConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/pages/contracts',
			component: lazy(() => import('./ContractPage'))
		},
		{
			path: '/pages/account',
			component: lazy(() => import('./AccountPage'))
		},
		{
			path: '/pages/messages',
			component: lazy(() => import('./MessagesPage'))
		},
		{
			path: '/pages/orders/:tab/:orderId',
			component: lazy(() => import('./OrdersPage'))
		},
		{
			path: '/pages/orders',
			component: lazy(() => import('./OrdersPage'))
		}
	]
};

export default ProfilePageConfig;
