const locale = {
	APPLICATIONS: 'Applications',
	DASHBOARDS: 'Tableau de bord',
	CALENDAR: 'Calendrier',
	ECOMMERCE: 'Commerce en ligne',
	ACADEMY: 'Formation',
	MAIL: 'Mails',
	TODO: 'Liste de taches',
	FILE_MANAGER: 'Gestion de fichiers',
	CONTACTS: 'Contacts',
	CHAT: 'Chat',
	SCRUMBOARD: 'Projet',
	NOTES: 'Notes'
};

export default locale;
